import $ from "clients/RawClient";
import ActionTypes from "./action-types.js";
import { createAction } from "redux-actions";
import Routes from "routes";

export const ActiveOrderIncludes = {
  UserMenuIcon: "groups.items",
  // Bag could just ask for the previewInvoice for its data, but to have a nice
  // gradual display of the bag with data we already have and placeholders for
  // data we need, we ask for groups.items.* similar to the UserMenuIcon request
  Bag: [
    "groups.items",
    "previewInvoice.groups.lineItems.orderItem.product.designer",
    "previewInvoice.groups.lineItems.orderItem.sku",
  ].join(","),
  Checkout: "", // default, server will handle
};

const actions = {
  activeOrdersLoadFailure: createAction(ActionTypes.ACTIVE_ORDERS_FAILURE),
  activeOrdersLoadSuccess: createAction(ActionTypes.ACTIVE_ORDERS_SUCCESS),
  activeOrdersLoading: createAction(ActionTypes.ACTIVE_ORDERS_LOADING),

  fetchActiveOrders(include) {
    return dispatch => {
      dispatch(actions.activeOrdersLoading(true));

      return $.ajax({
        url: Routes.NewCheckout.activeOrders,
        type: "GET",
        data: { include },
      })
        .then(
          activeOrders => {
            dispatch(actions.activeOrdersLoadSuccess({ activeOrders, include }));
          },
          error => {
            dispatch(actions.activeOrdersLoadFailure(error));
          }
        )
        .finally(() => {
          dispatch(actions.activeOrdersLoading(false));
        });
    };
  },
};

export default actions;

export const { activeOrdersLoadFailure, activeOrdersLoadSuccess, activeOrdersLoading, fetchActiveOrders } = actions;
