import $ from "clients/RawClient";
import _ from "underscore";
import { createAction } from "redux-actions";

import ActionTypes from "actions/action-types";
import ActionLogger from "action-logger";
import { NewCheckout } from "routes";
import { getUnheldItems } from "helpers/checkout-helpers";
import smartComponentActions from "./smart-component-actions";
import { bagTabs, switchBagTab, toggleBag } from "actions/bag-actions";
import classicBagActions, { ActiveOrderIncludes } from "actions/classic-bag-actions";
import { closeProductDrawer } from "actions/product-drawer-actions";
import { createAjaxAuthRetry } from "helpers/ajax-helpers";

const { addPro, refreshOrder: refreshOrderRoute, removeItem, updateGroup, updateMultipleGroups } = NewCheckout;

function updateOrderGroupAPI(payload, onSuccess, onFailure, dispatch) {
  // data should take the form { addressId: 12345, otherKey: "foo", ... }
  const { orderId, groupId, data } = payload;

  dispatch(actions.updateOrderGroupSubmitting());

  return createAjaxAuthRetry({
    url: updateGroup(orderId, groupId),
    type: "PATCH",
    data: { attributes: data },
  })
    .then(({ data }) => {
      dispatch(actions.updateOrderGroupSuccess(data));

      // Leaving the refreshOrder action here because all calling code will need to do this.
      // At this point, we may need to show a different banner on Checkout, e.g.
      // if the User adds PRO to their Order from the upsell, so let's fetch the
      // banner again.
      const getSmartCheckoutBannerThunk = _.partial(dispatch, smartComponentActions.getSmartCheckoutBanner());
      dispatch(actions.refreshOrder(orderId, getSmartCheckoutBannerThunk));

      if (onSuccess) {
        onSuccess(data);
      }

      return data;
    })
    .catch(error => {
      dispatch(actions.updateOrderGroupFailure(error.jqXHR));
      dispatch(actions.rebookOrderGroupFailure(error.jqXHR));

      if (onFailure) {
        onFailure(error.jqXHR);
      }

      // Throw so we don't hit the next .then block
      throw error;
    });
}

const updateGroupSuccess = (status, data) => {
  return function (dispatch) {
    if (status === 207) {
      dispatch(actions.updateMultipleOrderGroupsPartialSuccess(data));
    } else {
      dispatch(actions.updateMultipleOrderGroupsSuccess(data));
    }
  };
};
const updateGroupFailure = (error, onFailure) => {
  return function (dispatch) {
    dispatch(actions.updateMultipleOrderGroupsFailure(error.jqXHR));
    dispatch(actions.rebookMultipleOrderGroupsFailure(error.jqXHR));

    onFailure && onFailure(error.jqXHR);

    // Throw so we don't hit the next .then block
    throw error;
  };
};
function updateMultipleOrderGroupsAPI(payload, onSuccess, onFailure, dispatch) {
  // data should take the form { addressId: 12345, otherKey: "foo", ... }
  const { orderId, data } = payload;

  dispatch(actions.updateOrderGroupSubmitting());

  return createAjaxAuthRetry({
    url: updateMultipleGroups(orderId),
    type: "PATCH",
    data: { attributes: data },
  })
    .then(({ data, jqXHR }) => {
      dispatch(updateGroupSuccess(jqXHR.status, data));

      // Leaving the refreshOrder action here because all calling code will need to do this.
      // At this point, we may need to show a different banner on Checkout, e.g.
      // if the User adds PRO to their Order from the upsell, so let's fetch the
      // banner again.
      const getSmartCheckoutBannerThunk = _.partial(dispatch, smartComponentActions.getSmartCheckoutBanner());
      dispatch(actions.refreshOrder(orderId, getSmartCheckoutBannerThunk));

      onSuccess && onSuccess(data);

      return data;
    })
    .catch(error => {
      dispatch(updateGroupFailure(error, onFailure));
    });
}

function recalculateTaxForOrderGroupsAPI(payload, onSuccess, onFailure, dispatch) {
  const { orderId, data } = payload;

  dispatch(actions.updateOrderGroupSubmitting());

  return createAjaxAuthRetry({
    url: updateMultipleGroups(orderId),
    type: "PATCH",
    data: { attributes: data },
  })
    .then(({ data: groupData, jqXHR }) => {
      dispatch(updateGroupSuccess(jqXHR.status, groupData));

      return new Promise((resolve, reject) => {
        dispatch(
          actions.refreshOrder(
            orderId,
            data => {
              onSuccess && onSuccess(data);
              /* ApplePay payment sheet does not react on redux updates,
               * that's why we need to return data by resolving or rejecting the promise.
               *  */
              resolve(groupData);
            },
            error => {
              onFailure && onFailure(error);
              reject(data);
            }
          )
        );
      });
    })
    .catch(error => {
      dispatch(updateGroupFailure(error, onFailure));
    });
}

function removeOrderItemAPI(orderId, itemId) {
  return $.ajax({
    url: removeItem(orderId, itemId),
    type: "DELETE",
  });
}

function rebookMultipleOrderGroupsSuccessDispatch(dispatch, payload, data) {
  const payloadKeys = _.keys(payload.data);
  // the response from PATCHing a group is the group, however, this group
  // is no longer part of the order. Instead, a new group has been created
  // in its place with the updated information (and items). This allows us
  // to inspect the return value for the original data used to create the
  // group, grabbing the information we need to revert the operation
  // lucky!
  dispatch(
    actions.rebookMultipleOrderGroupsSuccess({
      previousOrderGroupModification: {
        orderId: payload.orderId,
        groupIds: _.map(data, "id"),
        data: _.chain(data).first().pick(payloadKeys).value(),
      },
    })
  );
}

const actions = {
  addPageError: createAction(ActionTypes.ADD_PAGE_ERROR),
  clearPageError: createAction(ActionTypes.CLEAR_PAGE_ERROR),
  removeOrderItemSuccess: createAction(ActionTypes.REMOVE_ORDER_ITEM_SUCCESS),
  removeOrderItemSubmitting: createAction(ActionTypes.REMOVE_ORDER_ITEM_SUBMITTING),
  removeOrderItemFailure: createAction(ActionTypes.REMOVE_ORDER_ITEM_FAILURE),
  addOrderItemSuccess: createAction(ActionTypes.ADD_ORDER_ITEM_SUCCESS),
  addOrderItemSubmitting: createAction(ActionTypes.ADD_ORDER_ITEM_SUBMITTING),
  addOrderItemFailure: createAction(ActionTypes.ADD_ORDER_ITEM_FAILURE),
  refreshHoldsSubmitting: createAction(ActionTypes.REFRESH_HOLDS_SUBMITTING),
  refreshHoldsSuccess: createAction(ActionTypes.REFRESH_HOLDS_SUCCESS),
  refreshHoldsFailure: createAction(ActionTypes.REFRESH_HOLDS_FAILURE),
  updateOrderGroupSubmitting: createAction(ActionTypes.UPDATE_ORDER_GROUP_SUBMITTING),
  rebookOrderGroupConfirmation: createAction(ActionTypes.REBOOK_ORDER_GROUP_CONFIRMATION),
  rebookMultipleOrderGroupsSuccess: createAction(ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_SUCCESS),
  rebookMultipleOrderGroupsFailure: createAction(ActionTypes.REBOOK_MULTIPLE_ORDER_GROUPS_FAILURE),
  rebookOrderGroupSuccess: createAction(ActionTypes.REBOOK_ORDER_GROUP_SUCCESS),
  rebookOrderGroupFailure: createAction(ActionTypes.REBOOK_ORDER_GROUP_FAILURE),
  rebookOrderGroupRevert: createAction(ActionTypes.REBOOK_ORDER_GROUP_REVERT),
  updateOrderGroupSuccess: createAction(ActionTypes.UPDATE_ORDER_GROUP_SUCCESS),
  updateOrderGroupFailure: createAction(ActionTypes.UPDATE_ORDER_GROUP_FAILURE),
  updateMultipleOrderGroupsSuccess: createAction(ActionTypes.UPDATE_MULTIPLE_ORDER_GROUPS_SUCCESS),
  updateMultipleOrderGroupsPartialSuccess: createAction(ActionTypes.UPDATE_MULTIPLE_ORDER_GROUPS_PARTIAL_SUCCESS),
  updateMultipleOrderGroupsFailure: createAction(ActionTypes.UPDATE_MULTIPLE_ORDER_GROUPS_FAILURE),

  updateOrderGroup(payload, onSuccess, onFailure) {
    return function (dispatch) {
      return updateOrderGroupAPI(payload, onSuccess, onFailure, dispatch)
        .then(data => {
          // the response from PATCHing a group is the group, however, this group
          // is no longer part of the order. Instead, a new group has been created
          // in its place with the updated information (and items). This allows us
          // to inspect the return value for the original data used to create the
          // group, grabbing the information we need to revert the operation
          // lucky!
          dispatch(
            actions.rebookOrderGroupSuccess({
              previousOrderGroupModification: {
                orderId: data.orderId,
                groupId: data.id, // this id has been updated... use the new one
                data: _.pick(data, _.keys(payload.data)),
              },
            })
          );
        })
        .catch(() => {
          /* Don't need to do anything here, just catching a thrown error so we don't hit the .then block on a network call error */
        });
    };
  },

  updateMultipleOrderGroups(payload, onSuccess, onFailure) {
    return function (dispatch) {
      return updateMultipleOrderGroupsAPI(payload, onSuccess, onFailure, dispatch)
        .then(data => {
          rebookMultipleOrderGroupsSuccessDispatch(dispatch, payload, data);
        })
        .catch(() => {
          /* Don't need to do anything here, just catching a thrown error so we don't hit the .then block on a network call error */
        });
    };
  },

  recalculateTaxForOrderGroups(payload, onSuccess, onFailure) {
    /*
     * ApplePay context:
     * 1. User changes shipping address in the payment sheet.
     * 2. New zipcode is sent to BE, and we receive updated order group.
     * 3. Once order group is received, we fetch updated order which contains recalculated tax.
     * */
    return function (dispatch) {
      return recalculateTaxForOrderGroupsAPI(payload, onSuccess, onFailure, dispatch)
        .then(data => {
          rebookMultipleOrderGroupsSuccessDispatch(dispatch, payload, data);
        })
        .catch(error => {
          /* This method is used with ApplePay hence onFailure is handling the error */
          onFailure && onFailure(error);
        });
    };
  },

  confirmUpdateOrder(invoice, onSuccess, onFailure) {
    return function (dispatch) {
      // get all unheld items
      const unheldItems = getUnheldItems(invoice);

      if (!unheldItems || !unheldItems.length) {
        if (onSuccess) {
          onSuccess();
        }

        return;
      }

      // call removeOrderItem for each
      const xhrs = _.map(unheldItems, item => removeOrderItemAPI(invoice.orderId, item.id));

      // when all are complete, dispatch confirmation action
      return Promise.all(xhrs).then(
        data => {
          if (onSuccess) {
            onSuccess();
          }

          dispatch(actions.rebookOrderGroupConfirmation(data));

          const getSmartCheckoutBannerThunk = _.partial(dispatch, smartComponentActions.getSmartCheckoutBanner());
          dispatch(actions.refreshOrder(invoice.orderId, getSmartCheckoutBannerThunk));
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }

          dispatch(actions.removeOrderItemFailure(err));
        }
      );
    };
  },

  revertUpdateOrderGroup(previousOrderGroupModification, onSuccess, onFailure) {
    return function (dispatch) {
      if (!previousOrderGroupModification) {
        return dispatch(actions.rebookOrderGroupFailure("Nothing to revert"));
      }

      updateOrderGroupAPI(previousOrderGroupModification, onSuccess, onFailure, dispatch)
        .then(() => {
          dispatch(actions.rebookOrderGroupRevert());
        })
        .catch(() => {
          /* Don't need to do anything here, just catching a thrown error so we don't hit the .then block on a network call error */
        });
    };
  },

  revertUpdateMultipleOrderGroups(previousOrderGroupModification, onSuccess, onFailure) {
    return function (dispatch) {
      if (!previousOrderGroupModification) {
        return dispatch(actions.rebookOrderGroupFailure("Nothing to revert"));
      }

      updateMultipleOrderGroupsAPI(previousOrderGroupModification, onSuccess, onFailure, dispatch)
        .then(() => {
          dispatch(actions.rebookOrderGroupRevert());
        })
        .catch(() => {
          /* Don't need to do anything here, just catching a thrown error so we don't hit the .then block on a network call error */
        });
    };
  },

  /**
  @param {Boolean} openBag - Determines if we should open bag or not upon ajax success
  */
  addOrderItem(payload, loggingData, onSuccess, onFailure) {
    return function (dispatch) {
      const { sku, subType, zipCode } = payload;
      dispatch(actions.addOrderItemSubmitting());
      $.ajax({
        url: NewCheckout.addOrderItem,
        type: "POST",
        data: { orderItem: { sku, subType, zipCode } },
      }).then(
        () => {
          if (onSuccess) {
            onSuccess(payload);
          }

          const action = ActionLogger.RezoAddToCart;
          ActionLogger.logAction({ ...loggingData, action });

          dispatch(toggleBag(true));
          dispatch(switchBagTab(bagTabs.CLASSIC_TAB));
          dispatch(actions.addOrderItemSuccess());
          dispatch(classicBagActions.fetchActiveOrders(ActiveOrderIncludes.Bag));

          // in case item was added in a product drawer, the drawer should close after successfully adding.
          // if it was added in the PDP, this won't change the redux state.
          //
          // it might make more sense to handle this within the product drawer component, but that would require this to
          // be an async action, so the component knows if/when the action was successful.
          // https://redux.js.org/advanced/async-actions
          dispatch(closeProductDrawer());
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
          dispatch(actions.addOrderItemFailure(err));
        }
      );
    };
  },

  removeOrderItem(orderId, itemId, onSuccess, onFailure) {
    return function (dispatch) {
      dispatch(actions.removeOrderItemSubmitting({ orderId, itemId }));

      removeOrderItemAPI(orderId, itemId).then(
        function (data) {
          dispatch(actions.removeOrderItemSuccess(data));
          if (onSuccess) {
            onSuccess(data);
          }
        },
        function (err) {
          dispatch(actions.removeOrderItemFailure(err));
          if (onFailure) {
            onFailure(err);
          }
        }
      );
    };
  },

  refreshOrder(orderId, onSuccess, onFailure) {
    return function (dispatch) {
      dispatch(actions.refreshHoldsSubmitting(orderId));

      $.ajax({
        url: refreshOrderRoute(orderId),
        type: "GET",
      }).then(
        function (data) {
          dispatch(actions.refreshHoldsSuccess(data));
          onSuccess && onSuccess(data);
        },
        function (err) {
          dispatch(actions.refreshHoldsFailure(err));
          onFailure && onFailure(err);
        }
      );
    };
  },

  // This function is called every five minutes to refresh holds for users in the checkout flow. It hits the same URL as
  // 'refreshOrder'. We do not care about the return signature as we just care about the side effect, ie.
  // extending a hold's expiry.
  extendOrderHold(orderId) {
    return function () {
      $.ajax({
        url: refreshOrderRoute(orderId),
        type: "GET",
      });
    };
  },

  // Used for adding PRO to a user's order while on Checkout.
  addProToOrder: function (orderId, onSuccess, onFailure) {
    return function (dispatch) {
      $.ajax({
        url: addPro(orderId),
        type: "POST",
      }).then(
        data => {
          dispatch(
            actions.refreshOrder(
              orderId,
              (...args) => {
                dispatch(smartComponentActions.getSmartCheckoutBanner(...args));

                // pass the onSuccess handler as the onSuccess method for refreshing
                // order. this way, callbacks can run with both the item added and the
                // updated order
                if (onSuccess) {
                  onSuccess(data);
                }
              },
              onFailure
            )
          );
        },
        err => {
          if (onFailure) {
            onFailure(err);
          }
        }
      );
    };
  },
};

export default actions;

export const {
  addPageError,
  clearPageError,
  removeOrderItemSuccess,
  removeOrderItemSubmitting,
  removeOrderItemFailure,
  addOrderItemSuccess,
  addOrderItemSubmitting,
  addOrderItemFailure,
  refreshHoldsSubmitting,
  refreshHoldsSuccess,
  refreshHoldsFailure,
  updateOrderGroupSubmitting,
  rebookOrderGroupConfirmation,
  rebookMultipleOrderGroupsSuccess,
  rebookMultipleOrderGroupsFailure,
  rebookOrderGroupSuccess,
  rebookOrderGroupFailure,
  rebookOrderGroupRevert,
  updateOrderGroupSuccess,
  updateOrderGroupFailure,
  updateMultipleOrderGroupsSuccess,
  updateMultipleOrderGroupsPartialSuccess,
  updateMultipleOrderGroupsFailure,
  updateOrderGroup,
  updateMultipleOrderGroups,
  recalculateTaxForOrderGroups,
  confirmUpdateOrder,
  revertUpdateOrderGroup,
  revertUpdateMultipleOrderGroups,
  addOrderItem,
  removeOrderItem,
  refreshOrder,
  extendOrderHold,
  addProToOrder,
} = actions;
